<template>
  <div v-if="settings" class="pb-40 h-full">
    <window-header></window-header>
    <div class="flex justify-center items-center flex-col ">
      <h2 class="text-2xl font-bold px-8 m-auto text-center">Nos engagements qualité</h2>
      <div class="px-16 pt-2">
        <div v-for="(item,index) in settings.config.items" :key="index" class="card-bg py-2 px-4 m-auto mt-1 bg-white bg-opacity-25 w-full rounded border-2 border-gray-500 ">
          <div class="flex">
            <div class="icon-wrapper w-10 flex items-center" v-html="content.getContent('quality' ,'icon' , item.key)" ></div>
            <div class="text-primary pl-3 font-bold flex items-center">
              {{content.getContent('quality' ,'title' , item.key)}}
            </div>
          </div>
          <div class="text-xs">
            {{content.getContent('quality' ,'text' , item.key)}}
          </div>
        </div>
      </div>
    </div>
    <window-footer></window-footer>
  </div>
</template>

<script>
  import windowFooter from '@/components/Footer'
  import windowHeader from '@/components/Header'
  import staticContentProvider from "@/services/staticContentProvider";
  import { mapGetters } from 'vuex';

  export default{
    props:['settings'],
    components:{
      windowFooter,
      windowHeader

    },
    data(){
      return {
        content : staticContentProvider
      }
    },
    computed:{
      ...mapGetters({assets : "getLoadedAssets"})
    },
    methods:{
      findAssetByIdentifier(identifier){
        return this.assets.find(item => item.identifier === identifier)
      }
    }
  }
</script>
