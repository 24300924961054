<template>
  <div v-if="settings" class="pb-40 h-full">
    <div class="w-full h-screen bg-center bg-cover" :style="`background-image: url(${getAssetUrl('resource_' + settings.value.id)})`">   </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default{
    props:['settings'],
    computed:{
      ...mapGetters({assets : "getLoadedAssets"})
    },
    methods:{
      getAssetUrl : function(identifier){
        if(!this.assets.find(item => item.identifier === identifier))
        {
          console.log(`asset ${identifier} not found`);
          return null;
        }
        return this.assets.find(item => item.identifier === identifier).url
      }
    }
  }
</script>
