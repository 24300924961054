<template>
  <div >
    <div class="flex justify-center px-16 pt-8 pb-4">
      <div class="inline-block p-5 bg-white rounded-lg" v-if="office.clubRousseau.shopwindow.showLogo" >
        <img :src="office.esLogo" class="h-full max-h-24">
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    computed:{
      ...mapGetters({office: "getOffice" })
    }
  }
</script>
