<template>
  <div  class="shopwindow-main-wrapper" >
    <Loader v-if="loading" />
    <div :class="`theme-bg-${settings.theme.name}`" v-else >
              <vueper-slides fade
                             :parallax="false"
                             class="no-shadow"
                             fixedHeight="100vh"
                             :arrows="false"
                             ref="slider"
                             @ready="initSlider"
                             @slide="handleSlide"
                             :bullets="true"
                             @before-slide="handleBeforeSlide"
              >
                <vueper-slide v-for="(slide , index) in views" :key="`slide_${index}`" >
                  <template #content>
                    <component v-bind:is="slide.type" :settings="slide" @next="next" ></component>
                  </template>
                </vueper-slide>
              </vueper-slides>
      </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import general from '@/components/slides/_GeneralInfo'
import financing from '@/components/slides/_Financing'
import times from '@/components/slides/_Times'
import bundles from '@/components/slides/_Bundles'
import social from '@/components/slides/_Social'
import club from '@/components/slides/_Club'
import news from '@/components/slides/_News'
import highlight from '@/components/slides/_Highlight'
import quality from '@/components/slides/_Quality'
import products from '@/components/slides/_Products'
import resources from '@/components/slides/_Resources'


import Loader from '@/components/Loader'
export default {
  name: 'ShopWindow',
  components: {
    VueperSlides,
    VueperSlide,
    Loader,
    general,
    financing,
    bundles,
    highlight,
    club,
    news,
    social,
    quality,
    products,
    resources,
    times
  },
  created(){
    const officeId = this.$route.params.officeId;
    this.loadSettings({officeId : officeId }).then(() => {

        this.handleSlide({
          currentSlide:{
            index : 0
          }
        })

        this.fetchConfigInterval = setInterval( () => {
          this.pollForNewSettings({officeId : officeId });
        } , this.fetchConfigInterval )

      });
  },
  data(){
    return {
      timeout : null,
      fetchConfigInterval : 30000,
      tick: null,
      currentSlideIndex: 0
    }
  },
  computed:{
    ...mapGetters({ views : "getViews" , loading: "getLoading" , settings : "getSettings", assetsLoaded: "getAssetsLoaded"}),
    ticks: function(){
      let ticks = this.views.map(view => Date.now());
      ticks.push(Date.now())
      return ticks ;
    }
  },
  methods:{
    ...mapActions(['loadSettings' , 'pollForNewSettings']),
    next(){
      if(this.$refs.slider.next)
        this.$refs.slider.next();
    },
    initSlider(){
      if(this.views[0].type === 'products' && this.views[0].mediaType === 'video'){
        this.$nextTick(
          () => {
            const video = document.getElementById('product_video_' + this.views[0].id);
            video.play();
          }
        );
      }
    },
    handleSlide(e){
      if(this.timeout)
        clearTimeout(this.timeout);

      console.log(`Displaying slide #${e.currentSlide.index}  (${ this.views[e.currentSlide.index].type}) for ${this.views[e.currentSlide.index].duration}s ` );

      if( this.views[e.currentSlide.index].mediaType !== 'video')
          this.timeout = setTimeout(this.next ,  this.views[e.currentSlide.index].duration * 1000)
    },
    handleBeforeSlide(e){

      const slideSettings = e.nextSlide.contentSlot[0].componentOptions.propsData.settings;
      if( slideSettings.type === 'products' && slideSettings.mediaType === 'video' )
      {
        this.$nextTick(
          () => {
            const video = document.getElementById('product_video_' + slideSettings.id);
            if(video){
              video.currentTime = 0;
              video.play();
            }
          }
        );
      }
      this.currentSlideIndex = e.currentSlide.index;
    }
  }
}
</script>

<style scoped lang="scss">
  .shopwindow-main-wrapper{
    color:#56595d
  }
</style>
