<template>
  <div style="height: 100vh">
   <div class="flex flex-col justify-center items-center h-full text-center">
       <svg  class="w-1/5 mt-auto inline-block" id="Logo_ClubRousseau" data-name="Logo ClubRousseau" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="218.206" height="218.207" viewBox="0 0 218.206 218.207" >
         <defs>
           <clipPath id="clip-path">
             <rect id="Rectangle_1" data-name="Rectangle 1" width="218.206" height="218.207" fill="none"/>
           </clipPath>
         </defs>
         <g id="Groupe_1" data-name="Groupe 1" clip-path="url(#clip-path)">
           <path id="Tracé_1" data-name="Tracé 1" d="M6.938,96.87s-12.235,12.235,0,24.468L96.87,211.27s12.234,12.234,24.468,0l89.931-89.932s12.235-12.235,0-24.468L121.338,6.938S109.1-5.3,96.87,6.938Z" fill="#fff"/>
           <path id="Tracé_2" data-name="Tracé 2" d="M6.938,96.87s-12.235,12.235,0,24.468L96.87,211.27s12.234,12.234,24.468,0l89.931-89.932s12.235-12.235,0-24.468L121.338,6.938S109.1-5.3,96.87,6.938Z" fill="none" stroke="#1d1d1b" stroke-width="3"/>
           <path id="Tracé_3" data-name="Tracé 3" d="M113.647,46.546a2.686,2.686,0,0,1,1.855,1.779,4.937,4.937,0,0,1-.229,2.871,16.47,16.47,0,0,1-1.829,3.607,38.859,38.859,0,0,1-3,3.989q-1.728,2.034-3.709,4.014t-3.965,3.659a3.4,3.4,0,0,0,.661,2.312,2.734,2.734,0,0,0,1.7.889,5.58,5.58,0,0,0,2.312-.2,12.087,12.087,0,0,0,2.49-1.016q1.066-.61.915.178a4.781,4.781,0,0,1-1.195,1.981,12.322,12.322,0,0,1-2.794,2.287,7.114,7.114,0,0,1-3.837,1.093,5.341,5.341,0,0,1-5.133-3.811,8.5,8.5,0,0,1-.1-4.371,27.152,27.152,0,0,1,1.727-5.437,37.724,37.724,0,0,1,2.973-5.564,32.172,32.172,0,0,1,3.685-4.751A15.006,15.006,0,0,1,110.065,47a4.457,4.457,0,0,1,3.582-.458m-9.2,13.923a23.1,23.1,0,0,0-1.117,2.947q1.879-1.88,3.811-4.192t3.43-4.4a26.168,26.168,0,0,0,2.236-3.583q.735-1.5.279-1.753c-.237-.134-.652-.025-1.245.331a9.719,9.719,0,0,0-2.083,1.88,27.881,27.881,0,0,0-2.566,3.506,40.6,40.6,0,0,0-2.745,5.259" fill="#1d1d1b"/>
           <path id="Tracé_4" data-name="Tracé 4" d="M123.281,61.633a3.578,3.578,0,0,1-.084,2.516,6.85,6.85,0,0,1-1.741,2.579,10.128,10.128,0,0,1-3.1,1.992,9.813,9.813,0,0,1-4.089.754,2.3,2.3,0,0,0,1.195,1.3,4.585,4.585,0,0,0,2.117.462,9.185,9.185,0,0,0,2.663-.441,10.061,10.061,0,0,0,2.831-1.4q.712-.462.838-.252t-.251.8a8.781,8.781,0,0,1-1.174,1.384,9.456,9.456,0,0,1-1.95,1.467,11.068,11.068,0,0,1-2.558,1.07,7.373,7.373,0,0,1-3,.188,6.707,6.707,0,0,1-2.789-.943,4.491,4.491,0,0,1-1.7-1.887,5.566,5.566,0,0,1-.482-2.642,8.991,8.991,0,0,1,.818-3.208,9.51,9.51,0,0,1,1.825-2.747,10.97,10.97,0,0,1,2.369-1.887,8.558,8.558,0,0,1,2.579-1.006,8.361,8.361,0,0,1,2.453-.168,4.585,4.585,0,0,1,1.991.629,2.977,2.977,0,0,1,1.238,1.447m-3.187,1.258A1.135,1.135,0,0,0,119,62.723a5.158,5.158,0,0,0-1.614.713,8.1,8.1,0,0,0-1.656,1.426,6.927,6.927,0,0,0-1.258,1.971,7.148,7.148,0,0,0,2.515-.525,8.554,8.554,0,0,0,1.993-1.111,4.006,4.006,0,0,0,1.153-1.279.814.814,0,0,0-.042-1.027" fill="#1d1d1b"/>
           <path id="Tracé_5" data-name="Tracé 5" d="M89.3,72.291H51.72a1.45,1.45,0,1,1,0-2.9H89.3a1.45,1.45,0,1,1,0,2.9" fill="#1d1d1b"/>
           <path id="Tracé_6" data-name="Tracé 6" d="M166.487,72.291h-36a1.45,1.45,0,0,1,0-2.9h36a1.45,1.45,0,0,1,0,2.9" fill="#1d1d1b"/>
           <path id="Tracé_7" data-name="Tracé 7" d="M166.782,124.936H52.023a1.449,1.449,0,0,1,0-2.9H166.782a1.449,1.449,0,0,1,0,2.9" fill="#1d1d1b"/>
           <path id="Tracé_8" data-name="Tracé 8" d="M53.043,132.77h4.475c3.71,0,6.423,1.3,6.423,4.917a4.32,4.32,0,0,1-2.574,4.035l4.291,6.053-3.989,1.275-4.638-7.514h-.046v6.958l-3.942.37Zm3.942,7.816c1.693.115,3.014-.441,3.014-2.319,0-1.9-1.391-2.2-3.014-2.133Z" fill="#1d1d1b"/>
           <path id="Tracé_9" data-name="Tracé 9" d="M82.586,140.725A8.489,8.489,0,1,1,74.1,132.4a8.414,8.414,0,0,1,8.488,8.326m-13.033,0a4.548,4.548,0,1,0,4.545-4.707,4.741,4.741,0,0,0-4.545,4.707" fill="#1d1d1b"/>
           <path id="Tracé_10" data-name="Tracé 10" d="M97.449,143.16c0,3.71-2.9,5.891-6.888,5.891-3.71,0-6.307-1.74-6.307-5.612V132.771L88.2,132.4v9.926c0,1.692.487,3.107,2.458,3.107,2.552,0,2.853-1.786,2.853-3.965v-8.7l3.942-.371Z" fill="#1d1d1b"/>
           <path id="Tracé_11" data-name="Tracé 11" d="M108.277,136.968a3.857,3.857,0,0,0-2.828-1.205c-.7,0-1.74.417-1.74,1.252,0,2.527,7.119.881,7.119,6.725,0,3.571-2.783,5.31-6.1,5.31a8.481,8.481,0,0,1-5.52-1.878l2.366-3.015a3.844,3.844,0,0,0,2.991,1.276c.812,0,2.087-.394,2.087-1.415,0-2.482-7.119-1-7.119-6.7,0-3.153,2.922-4.916,5.8-4.916a8.183,8.183,0,0,1,5.1,1.832Z" fill="#1d1d1b"/>
           <path id="Tracé_12" data-name="Tracé 12" d="M121.17,136.968a3.857,3.857,0,0,0-2.828-1.205c-.7,0-1.74.417-1.74,1.252,0,2.527,7.119.881,7.119,6.725,0,3.571-2.783,5.31-6.1,5.31a8.481,8.481,0,0,1-5.52-1.878l2.366-3.015a3.844,3.844,0,0,0,2.991,1.276c.812,0,2.087-.394,2.087-1.415,0-2.482-7.119-1-7.119-6.7,0-3.153,2.922-4.916,5.8-4.916a8.183,8.183,0,0,1,5.1,1.832Z" fill="#1d1d1b"/>
           <path id="Tracé_13" data-name="Tracé 13" d="M125.6,148.679V132.771h9l.7,3.363h-5.751v2.759h4.105v3.362h-4.105v3.062h6.238l-.974,3.362Z" fill="#1d1d1b"/>
           <path id="Tracé_14" data-name="Tracé 14" d="M143.685,132.4l8.581,15.838-4.013.812-1.623-3.409h-6.052l-1.6,3.409-3.827-.812,8.488-15.838Zm-2.156,10.3h4.22l-2.11-3.9Z" fill="#1d1d1b"/>
           <path id="Tracé_15" data-name="Tracé 15" d="M165.762,143.16c0,3.71-2.9,5.891-6.888,5.891-3.71,0-6.307-1.74-6.307-5.612V132.771l3.942-.371v9.926c0,1.692.487,3.107,2.458,3.107,2.552,0,2.853-1.786,2.853-3.965v-8.7l3.942-.371Z" fill="#1d1d1b"/>
           <path id="Tracé_16" data-name="Tracé 16" d="M90.1,156.916a9.057,9.057,0,1,1-8.881,9.055,8.976,8.976,0,0,1,8.881-9.055m0,13.9a4.852,4.852,0,1,0-5.022-4.849A5.058,5.058,0,0,0,90.1,170.82" fill="#e5332a"/>
           <path id="Tracé_17" data-name="Tracé 17" d="M110.73,156.916a9.057,9.057,0,1,1-8.881,9.055,8.976,8.976,0,0,1,8.881-9.055m0,13.9a4.852,4.852,0,1,0-5.022-4.849,5.058,5.058,0,0,0,5.022,4.849" fill="#f29100"/>
           <path id="Tracé_18" data-name="Tracé 18" d="M131.364,156.916a9.057,9.057,0,1,1-8.881,9.055,8.976,8.976,0,0,1,8.881-9.055m0,13.9a4.852,4.852,0,1,0-5.022-4.849,5.058,5.058,0,0,0,5.022,4.849" fill="#93c01f"/>
           <path id="Tracé_19" data-name="Tracé 19" d="M70.632,78.011c7.53,0,15.541,4.059,17.25,13.3H80.138c-1.655-4.219-5.02-6.089-9.506-6.089-6.622,0-11.055,4.807-11.055,11.91,0,6.409,4.326,11.75,11,11.75,4.647,0,8.439-2.19,9.72-6.73h7.69c-1.6,9.881-9.559,13.993-17.356,13.993-9.826,0-19.119-6.623-19.119-19.013,0-12.443,9.293-19.279,19.119-19.119" fill="#1d1d1b"/>
           <path id="Tracé_20" data-name="Tracé 20" d="M100.536,76.676V106c0,3.952,1.549,4.753,5.127,4.166l.267,5.607c-7.957,1.442-12.764-.534-12.764-9.773V76.676Z" fill="#1d1d1b"/>
           <path id="Tracé_21" data-name="Tracé 21" d="M134.075,90.027v18c0,2.4.106,3.258,2.4,3.1l.053,4.647c-3.632.854-9.4,1.228-9.4-3.739-1.442,2.991-5.02,4.113-8.225,4.113-7.423,0-9.56-4.967-9.56-11.376V90.027h7.531v14.74c-.16,3.632,1.816,5.5,4.432,5.5,2.992.053,5.287-2.19,5.287-5.661V90.027Z" fill="#1d1d1b"/>
           <path id="Tracé_22" data-name="Tracé 22" d="M141.549,76.783h7.424V90.989a15.777,15.777,0,0,1,5.982-1.228c7.156-.054,12.337,5.394,12.337,13.511,0,10.041-7,13.4-14.847,13.352a34.2,34.2,0,0,1-10.9-2.03Zm7.264,33.165a15.436,15.436,0,0,0,4.486.641c3.791-.054,6.515-2.083,6.515-7.317,0-4.646-2.67-7.316-6.142-7.476a9.3,9.3,0,0,0-4.859,1.335Z" fill="#1d1d1b"/>
         </g>
       </svg>
       <div class="mb-auto mt-5">
         <div class="lds-ring" v-if="!configLoadingError"><div></div><div></div><div></div><div></div></div>
         <div v-html="loadingMessage"></div>
       </div>
     </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
  export default {
    name: 'Loader',
    components: {
    },
    computed:{
      ...mapGetters({loadingMessage: "getLoadingMessage" , loadedAssets: "getLoadedAssets" , configLoadingError: "getConfigLoadingError"})
    }
  };
</script>

