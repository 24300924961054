<template>
  <div v-if="settings" class="pb-40 h-full">
    <window-header></window-header>
    <h2 class="text-2xl font-bold px-8 m-auto text-center">Horaires</h2>
    <div class="flex flex-col justify-center items-center flex-col px-8 h-3/4">

      <div class="flex items-center justify-center flex-col w-full">
        <div v-if="settings.config.showOpeningTimes" class="w-full m-auto">
          <h3 class="text-2xl text-primary font-bold py-8 text-center pb-3 pt-0">Ouverture de l’auto-école</h3>
          <div class="px-8 w-full">
            <table class="w-full text-xl">
              <tr>
                <th></th>
                <th class="font-normal text-lg  w-36">
                  <span  v-if="showPeriodHeader('ouverture' , 'AM')">
                    Matin
                  </span>
                </th>
                <th  class="font-normal text-lg  w-36">
                   <span  v-if="showPeriodHeader('ouverture' , 'PM')">
                    Après-Midi
                   </span>
                </th>
              </tr>
              <tr v-for="(day, index) in days.filter( day => ( isOpen( 'ouverture' , day ,'AM')) || isOpen( 'ouverture' , day ,'PM'))" :key="index">
                <td>
                  {{day}}
                </td>
                <td class="text-center w-36 ">
                <span v-if="isOpen( 'ouverture' , day ,'AM')">
                  {{getTimes( 'ouverture', day ,'AM')}}
                </span>
                </td>
                <td class="text-center w-36">
                 <span v-if="isOpen( 'ouverture' , day ,'PM')">
                   {{getTimes( 'ouverture', day ,'PM')}}
                 </span>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="px-8 w-full mx-8 mt-8" v-if="settings.config.showOpeningTimes && settings.config.showTheoryTimes" >
          <hr class="w-full  border-gray-400"/>
        </div>

        <div v-if="settings.config.showTheoryTimes" class="w-full">
          <h3 class="text-2xl text-primary font-bold py-8 text-center pb-3">Cours théoriques</h3>
          <div class="px-8 w-full">
            <table class="w-full text-xl">
              <tr>
                <th></th>
                <th class="font-normal text-lg  w-36">
                  <span  v-if="showPeriodHeader('theorique' , 'AM')">
                    Matin
                  </span>
                </th>
                <th  class="font-normal text-lg w-36">
                   <span  v-if="showPeriodHeader('theorique' , 'PM')">
                    Après-Midi
                   </span>
                </th>
              </tr>
              <tr v-for="(day, index) in days.filter( day => ( isOpen( 'theorique' , day ,'AM')) || isOpen( 'theorique' , day ,'PM'))" :key="index">
                <td>
                  {{day}}
                </td>
                <td  class="text-center w-36">
                <span v-if="isOpen( 'theorique' , day ,'AM')">
                  {{getTimes( 'theorique', day ,'AM')}}
                </span>
                </td>
                <td class="text-center w-36">
                 <span v-if="isOpen( 'theorique' , day ,'PM')">
                   {{getTimes( 'theorique', day ,'PM')}}
                 </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <window-footer></window-footer>
  </div>
</template>

<script>
  import windowFooter from '@/components/Footer'
  import windowHeader from '@/components/Header'
  import { mapGetters } from 'vuex';

  export default{
    props:['settings'],
    components:{
      windowFooter,
      windowHeader
    },
    data(){
      return {
        days: ['Lundi' , 'Mardi' , 'Mercredi' , 'Jeudi' , 'Vendredi' , 'Samedi' , 'Dimanche']
      }
    },
    computed:{
      ...mapGetters({office : "getOffice"})
    },
    methods: {
      isOpen(  type  , day , period  ){
          if( this.office.clubRousseau.horaires[type][day][`HOR_DEBUT_${period}`] !== null )
              return true;

          return false;
      },
      showPeriodHeader( type, period ){
        for( let day in this.office.clubRousseau.horaires[type])
          if( this.isOpen( type, day, period ) )
            return true;

          return false;
      },
      getTimes( type  , day  , period ){
        const times = [
          this.office.clubRousseau.horaires[type][day][`HOR_DEBUT_${period}`],
          this.office.clubRousseau.horaires[type][day][`HOR_FIN_${period}`]
        ];

        return times.join(' - ' );
      }
    }
  }
</script>
