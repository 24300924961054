<template>
  <div v-if="settings" class="pb-40 h-full">
    <window-header></window-header>
    <div class="flex justify-center items-center flex-col px-8 ">
      <h2 class="text-2xl font-bold px-8 m-auto   text-center" >Notre auto-école est membre du Club{{'\xa0'}}Rousseau</h2>
      <div>
        <p class="p-6 text-center">En poussant notre porte, vous allez bénéficier de tout le savoir-faire d’une auto-école de proximité. Nos enseignants sont reconnus pour leurs compétences pédagogiques et auront à cœur de vous former à devenir des conducteurs responsables.</p>
        <img src="/images/france.svg" class="w-full h-80"/>
        <p class="p-6 text-center">Forts d’un réseau de plus de 2 000 adhérents, nous travaillons en partenariat avec Codes Rousseau, acteur phare de la formation à la sécurité routière, ce qui nous permet de vous faire bénéficier des dernières innovations en termes d’apprentissage.</p>
      </div>
    </div>
    <window-footer></window-footer>
  </div>
</template>

<script>
  import windowFooter from '@/components/Footer'
  import windowHeader from '@/components/Header'
  import { mapGetters } from 'vuex';

  export default{
    props:['settings'],
    components:{
      windowFooter,
      windowHeader
    },
    computed:{
      ...mapGetters({office : "getOffice"})
    }
  }
</script>
