<template>
  <div v-if="settings" class="pb-40 h-full">
    <window-header></window-header>
    <h2 class="text-2xl font-bold px-8 m-auto   text-center" >Les formations</h2>
    <div class="text-center flex items-center justify-center mt-2">

      <div v-if="hasCar" class="rounded-full p-3 bg-white h-16 w-16  items-center inline-block my-auto flex mx-2">
        <svg id="Groupe_10" data-name="Groupe 10" xmlns="http://www.w3.org/2000/svg" width="101.644" height="35.284" viewBox="0 0 101.644 35.284">
          <path id="Tracé_46" data-name="Tracé 46" class="cls-1" d="M16.246,76.693c2.59.443,2.144.034,2.076-1.988-.017-.5-.018-1.02-.018-1.55a10.664,10.664,0,0,1,21.329,0c0,.8-.016,1.587-.092,2.34-.255,2.488.236,2.268,2.069,2.492l42.856.046c2.522-.13,1.7.143,1.521-2.483-.054-.769-.055-1.571-.055-2.4a10.665,10.665,0,0,1,21.329,0c0,.6-.009,1.183-.045,1.75-.106,1.655.274,1.587,1.511,1.822l2.549-.083a2.952,2.952,0,0,0,2.9-2.089,16.534,16.534,0,0,0,.355-4.792c.016-1.13-.2-1.966-1.109-2.04-.378-.907-.77-1.842-1.6-3.44a6.007,6.007,0,0,0-4.259-3.293c-5.085-1.368-13.628-2.9-24.1-4.705A79.462,79.462,0,0,0,65.3,47.5c-7.97-2.533-25.754-1.155-33.887.013-1.638.236-1.267.39-2.792,1.029-2.31.969-8.4,4.552-10.524,5.867a4.474,4.474,0,0,0-2.346,3.512c-.3,2.834-1.491,7.9-1.65,10.663-.026.454-1.131.457-1.135.892-.02,1.79-.384,5.282.821,6.4a5.573,5.573,0,0,0,2.463.813M50.4,57.179c.18,1.472.083,1.073-1.549,1.054-5.824-.071-12.333-.685-18.163-.88a1.147,1.147,0,0,1-1.022-.552c-.315-.469,1.643-2.744,1.906-3.1.683-.934,3.41-4.036,4.741-4.409,3.749-.869,8.059-.8,11.931-.988a1.256,1.256,0,0,1,.913.636c.505,1.307.586,2.864,1.243,8.243m22.138,1.893c.058.229-12.731-.687-17.5-.727-1.209-.01-1.488-.29-1.671-1.472-.378-2.448-1-6.1-1.26-7.783-.08-.529-.1-.8.536-.79,3.672.077,8.285.1,11.711,1.252A51.841,51.841,0,0,1,76.8,55.794c-1.614-.1-4.737,0-4.652,1.424a8.572,8.572,0,0,0,.386,1.854" transform="translate(-12.895 -46.076)"/>
          <path id="Tracé_47" data-name="Tracé 47" class="cls-2" d="M28.969,68.087A5.069,5.069,0,1,1,23.9,73.156a5.075,5.075,0,0,1,5.069-5.069m0-3.134a8.2,8.2,0,1,0,8.2,8.2,8.2,8.2,0,0,0-8.2-8.2" transform="translate(-12.895 -46.076)"/>
          <path id="Tracé_48" data-name="Tracé 48" class="cls-2" d="M96.6,68.087a5.069,5.069,0,1,1-5.069,5.069A5.075,5.075,0,0,1,96.6,68.087m0-3.134a8.2,8.2,0,1,0,8.2,8.2,8.2,8.2,0,0,0-8.2-8.2" transform="translate(-12.895 -46.076)"/>
        </svg>
      </div>

       <div v-if="hasBike" class="rounded-full p-3 bg-white  h-16 w-16    items-center inline-block my-auto flex mx-2">
         <svg id="Groupe_27" data-name="Groupe 27" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="82.337" height="50" viewBox="0 0 82.337 50">
           <g id="Groupe_26" data-name="Groupe 26" class="cls-2">
             <path id="Tracé_78" data-name="Tracé 78" class="cls-1" d="M60.051,13.62,57.393,8.17a1.46,1.46,0,0,0-2.721.242L52.3,8.406h0a1.026,1.026,0,0,0,0,2.052l2.957.008,1.1,2.259,1.411,1.221a9.441,9.441,0,0,0,2.287-.326M30.193,20.59l-1.45.008A9.319,9.319,0,0,1,19.6,11.223v-.448H19.7c1.3,2.8,3.48,3.746,8.212,3.746.808.026,3.431-.014,4.409-.014,0,1.969.325,6.083-2.133,6.083M72.986,30.721a7.724,7.724,0,0,0-2.42-.5l-1.784-3.573,3.239-2.681.088-.073c5.1.127,8.822,3.525,9.1,4.9l-3.6-.021c-2.412,0-3.809.726-4.621,1.946m-8.181-.669.044-.041c.172-.157.343-.314.521-.473l0,0c.547-.492,1.113-.99,1.713-1.5l2.645,5.425a4.437,4.437,0,0,1,.532-.054,4.519,4.519,0,1,1-3.179,1.312l-1.424-2.918a7.672,7.672,0,1,0,9.124-.07c.552-.7,1.43-.909,2.814-.909l2.421.014A12.063,12.063,0,1,1,58.665,41.252V39.726c0-3.178,2.367-6.215,6.14-9.674m-62.3-5.291L.143,23.027l2.113-2.878c.838.326,1.8.7,2.823,1.1ZM.006,37.926a12.064,12.064,0,0,0,22.929,5.252l1.755.187c.092-7.115-1.772-10.725-1.772-10.725-.074-.153-.153-.3-.234-.453A12.062,12.062,0,0,0,.006,37.926m4.4,0a7.669,7.669,0,0,1,13.68-4.771l-5.776.615c-.046.006-.353.052-.365.056a4.154,4.154,0,0,0-.008,8.166l.373.054,5.8.618A7.668,7.668,0,0,1,4.4,37.926m12.7-14.868C10.651,20.176,0,16.141,0,16.141c0-3.336,5.913-5.665,10.088-5.665h6.58v.747A12.206,12.206,0,0,0,28.743,23.53h.008l1.442-.009a4.517,4.517,0,0,0,3.294-1.358c1.852-1.878,1.807-4.878,1.775-7.068l0-2.311c2-2.623,6.063-2.823,7.769-2.823l5.678.024L56.7,16.9c7.3,0,7.361-2.639,7.361-7.03L60.588,6.818A5.455,5.455,0,0,1,58.863,0s4.025,3.274,9.5,8.212,5.463,9.6,5.463,10.494c-10.393,8.587-18.089,13.856-18.089,21.02v3.845H27.622c0-8.761-1.6-16.532-10.516-20.513"/>
           </g>
         </svg>
      </div>

       <div v-if="hasBoat" class="rounded-full p-3 bg-white  h-16 w-16   items-center inline-block my-auto flex mx-2">
         <svg id="Groupe_276" data-name="Groupe 276" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="101.991" height="23.406" viewBox="0 0 101.991 23.406">
           <defs>
             <clipPath id="clip-path">
               <rect id="Rectangle_868" data-name="Rectangle 868" width="101.991" height="23.406" fill="#202020"/>
             </clipPath>
           </defs>
           <g id="Groupe_275" data-name="Groupe 275" transform="translate(0 0)" clip-path="url(#clip-path)">
             <path id="Tracé_710" data-name="Tracé 710" d="M1.108,15.582A3.066,3.066,0,0,0,0,17.682v.094c.051,2.13,3.329,4.064,8.9,5.63H86.685C95.05,20.422,101.813,10.9,101.991,8.3c0,0-15.952.77-28.9.792C68.982,5.182,59.894,1.1,49.188.189,34.3-1.078,21.918,4.257,21.533,8.79a2.243,2.243,0,0,0-.007.312c-1.422.22-2.793.454-4.1.7A7.155,7.155,0,0,1,10.4,15.582ZM54.68,2.461C62.328,4,68.568,6.671,70.667,9.088H41.626c-3.75-1.707-5.98-3.6-5.668-5.159C36.512,1.159,44.9.5,54.68,2.461m18.368,8.167H21.479v-.394H73.048ZM9.177,21.948H86.756v.447H9.177Z" transform="translate(0 -0.001)" fill="#202020"/>
           </g>
         </svg>
      </div>
    </div>

    <div class="flex  flex-col px-16 h-3/4 mb-auto pt-4">
      <div v-for=" (bundle,index) in settings.config" :key="index" class="card-bg bundle bg-white bg-opacity-25 w-full rounded border-2 border-gray-300 px-3 py-1 text-center mb-2">
        <span class="font-bold">{{bundle.formulaType.label}}</span><br />
        <span class="font-bold text-primary text-xl">{{(bundle.price / 100).toFixed(2)}}€</span>
      </div>
    </div>
    <window-footer></window-footer>
  </div>
</template>

<script>
  import windowFooter from '@/components/Footer'
  import windowHeader from '@/components/Header'

  export default{
    props:['settings'],
    components:{
      windowFooter,
      windowHeader
    },
    computed:{
      hasCar : function(){
        return  this.settings.config.filter( item => item.formulaType.training.license.licenseGroup.label === 'Voiture').length > 0
      },
      hasBoat : function(){
        return  this.settings.config.filter( item => item.formulaType.training.license.licenseGroup.label === 'Bateau').length > 0
      },
      hasBike : function(){
        return  this.settings.config.filter( item => item.formulaType.training.license.licenseGroup.label === 'Deux-roues').length > 0
      },
    }
  }
</script>
