<template>
  <div v-if="settings" class="pb-40 h-full">
    <window-header></window-header>
    <div class="flex justify-center items-center flex-col px-8  h-3/4 ">
      <div class="flex flex-col justify-center items-center">
        <div class="m-auto">
          <svg class="fill-current" id="icone_campaign" data-name="icone campaign" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="250" height="250" viewBox="0 0 400 400">
            <g id="Groupe_24" data-name="Groupe 24" class="cls-2">
              <path id="Tracé_61" data-name="Tracé 61" class="cls-1" d="M18,27.667h0A16.716,16.716,0,0,0,34.667,44.333H68A16.667,16.667,0,1,0,68,11H34.667A16.716,16.716,0,0,0,18,27.667" transform="translate(282 172.333)"/>
              <path id="Tracé_62" data-name="Tracé 62" class="cls-1" d="M19.707,23.484A16.1,16.1,0,0,0,23.04,46.317c8.833,6.5,18.167,13.5,27,20.167a16.3,16.3,0,0,0,23-3.333c0-.167.167-.167.167-.333a16.3,16.3,0,0,0-3.333-23C61.04,33.151,51.707,26.151,43.04,19.651a16.546,16.546,0,0,0-23.167,3.5.582.582,0,0,1-.167.333" transform="translate(256.793 256.849)"/>
              <path id="Tracé_63" data-name="Tracé 63" class="cls-1" d="M73.117,11.373c0-.167-.167-.167-.167-.333a16.339,16.339,0,0,0-23-3.333c-8.833,6.667-18.333,13.667-27,20.333a16.311,16.311,0,0,0-3.167,23c0,.167.167.167.167.333a16.319,16.319,0,0,0,23,3.333c8.833-6.5,18.167-13.667,27-20.333,7.167-5.333,8.5-15.667,3.167-23" transform="translate(257.049 68.793)"/>
              <path id="Tracé_64" data-name="Tracé 64" class="cls-1" d="M102,56H35.333A33.431,33.431,0,0,0,2,89.333v33.333A33.431,33.431,0,0,0,35.333,156H52v50a16.667,16.667,0,1,0,33.333,0V156H102l83.333,50V6Z" transform="translate(31.333 94)"/>
              <path id="Tracé_65" data-name="Tracé 65" class="cls-1" d="M39,64.483A74.667,74.667,0,0,0,14,8.65v111.5A74.038,74.038,0,0,0,39,64.483" transform="translate(219.333 135.517)"/>
            </g>
          </svg>
        </div>
        <div class="m-auto">
          <h2 class="text-5xl text-primary font-bold px-8 text-center">À la une !</h2>
        </div>
        <div class="m-auto">
          <p class="p-6 text-center text-3xl font-bold">
          {{office.clubRousseau.accueil.ACTU_TEXTE}}
          </p>
        </div>
      </div>
    </div>
    <window-footer></window-footer>
  </div>
</template>

<script>
  import windowFooter from '@/components/Footer'
  import windowHeader from '@/components/Header'
  import { mapGetters } from 'vuex';

  export default{
    props:['settings'],
    components:{
      windowFooter,
      windowHeader
    },
    computed:{
      ...mapGetters({office : "getOffice"})
    }
  }
</script>
