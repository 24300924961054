<template>
  <div v-if="settings" class="pb-40 h-full" :data-tick="tick">
    <div class="player-wrapper w-full h-screen" v-if="settings.mediaType === 'video'">
      <video  muted playsinline class="w-full" :id="`product_video_${settings.id}`"   @ended="videoEnded">
        <source :src="`/media/${settings.id}.webm`" type="video/webm">
        <source :src="`/media/${settings.id}.mp4`" type="video/mp4">
        <source :src="`/media/${settings.id}.ogv`" type="video/ogg">
      </video>
    </div>

    <div class="w-full h-screen bg-center bg-cover" :style="`background-image: url(${getAssetUrl('product_' + settings.id)})`" v-else>
    </div>

    <window-footer v-if="settings.mediaType !== 'video'"></window-footer>
  </div>
</template>

<script>
  import windowFooter from '@/components/Footer'
  import { mapGetters } from 'vuex';

  export default{
    props:['settings','tick' ],
    components:{
      'window-footer' : windowFooter,
    },
    computed:{
      ...mapGetters({assets : "getLoadedAssets"})
    },
   mounted: function(){
      if(this.settings.mediaType === 'video' ){
        this.$nextTick(() => {
          this.addListenerMulti(document.getElementById(`product_video_${this.settings.id}`), 'canplaythrough play abort  ended error interruptbegin interruptend loadeddata loadedmetadata loadstart  pause play playing  waiting' , (e) => {
            console.log(`Video ${this.settings.id} : ${e.type}`)
          })
        });

      }
    },
    methods:{
    addListenerMulti(el, s, fn) {
        s.split(' ').forEach(e => el.addEventListener(e, fn, false));
    },
    getAssetUrl : function(identifier){
          if(!this.assets.find(item => item.identifier === identifier))
          {
            console.log(`asset ${identifier} not found`);
            return null;
          }
          return this.assets.find(item => item.identifier === identifier).url
     },
      videoEnded(){
      this.$emit('next');
      }
    }
  }
</script>
