<template>
  <div v-if="settings" class="pb-40 h-full">
    <window-header></window-header>
    <div class="flex justify-center items-center flex-col px-8  h-3/4 ">
      <div class="flex flex-col justify-center items-center">
        <div class="m-auto news-icon-wrapper my-6 mb-0" v-html="content.getNewsIcon(settings.filename)">

        </div>
        <div class="m-auto">
          <h2 class="text-5xl text-primary font-bold px-8 text-center">{{settings.title}}</h2>
        </div>
        <div class="m-auto">
          <p class="p-6 text-center text-3xl font-bold">
            {{settings.body}}
          </p>
        </div>
      </div>
    </div>
    <window-footer></window-footer>
  </div>
</template>

<script>
  // import Icon from '@/components/_Icon'
  import windowFooter from '@/components/Footer'
  import windowHeader from '@/components/Header'
  import { mapGetters } from 'vuex';
  import staticContentProvider from "@/services/staticContentProvider";

  export default{
    props:['settings'],
    components:{
      // Icon,
      windowFooter,
      windowHeader
    },
    data(){
      return {
        content : staticContentProvider
      }
    },
    computed:{
      ...mapGetters({office : "getOffice"})
    }
  }
</script>
