import moment from 'moment';
const now = moment();

const staticAssets = [
  { url : "/backgrounds/sign.jpg" , identifier: "bg1" , type: 'image'},
  { url : "/backgrounds/light.jpg" , identifier: "bg2"  , type: 'image'},
  { url : "/backgrounds/dark.jpg" , identifier: "bg3"  , type: 'image'},
  { url : "/backgrounds/blue.jpg" , identifier: "bg4"  , type: 'image'},
  { url : "/backgrounds/road.jpg" , identifier: "bg5"  , type: 'image'},
  { url : "/images/france.svg" , identifier: "club_pic"  , type: 'image'}
];

Object.defineProperty(Array.prototype, 'chunk', {
  value: function(chunkSize) {
    let that = this;
    return Array(Math.ceil(that.length/chunkSize)).fill().map(function(_,i){
      return that.slice(i*chunkSize,i*chunkSize+chunkSize);
    });
  }
});

export default{

  buildViews(slides , context){

    const items = [];
    const activeBundles = Object.keys( context.state.office.clubRousseau.formules );

    slides.forEach( function(slide){
      switch(true) {
        case slide.type === "bundles":
          let bundles = slide.config.car
            .concat(slide.config.bike)
            .concat(slide.config.boat)
            .filter( item => activeBundles.includes(item))
            .map( item => context.state.office.clubRousseau.formules[item]);

          const pages = bundles.chunk(8);
          pages.forEach( page => {
            items.push({...slide, config: page , duration: slide.duration })
          });
          break;

        case slide.type === "highlight":
              if(now.isAfter(context.state.office.clubRousseau.accueil.ACTU_DEBUT) && now.isBefore(context.state.office.clubRousseau.accueil.ACTU_FIN))
                items.push( slide );
          break;

        case slide.type === "news":
              if(now.isAfter(slide.displayStartDate) && now.isBefore(slide.displayEndDate) )
                items.push( slide );
          break;

        case slide.type === "products" :
              if(context.state.loadedAssets.find( loadedAsset => loadedAsset.loaded && loadedAsset.identifier === `product_${slide.id}`))
                items.push( {...slide , image: `/media/${slide.id}.webm`} );
          break;

        default:
          items.push( slide );
          break;
      }

    })

    return items;
  },

  getAssetList( office , loadedAssets = []){
    let items = staticAssets;
    const shopWindow = office.clubRousseau.shopwindow;

    if(shopWindow.showLogo && office.logo){
        items.push({ type: 'image', url : office.logo , identifier: 'logo'  })
    }

    shopWindow.pages.products.config.items.forEach(function(item , index){
      if(item.mediaType !== 'video')
          items.push({ type: 'image' , url : `${item.image}` , identifier: `product_${item.id}`})
      else
        items.push({ type: 'video' , url : `/media/${item.id}.webm` , identifier: `product_${item.id}`})
    });

    if(shopWindow.pages.resources){
      shopWindow.pages.resources.config.items.forEach(function(item){
        items.push({ type: 'image' , url : `${item.value.properties.url}` , identifier: `resource_${item.value.id}`})
      });
    }

    return items.filter(function(asset){
          return loadedAssets.filter(
            function(loadedAsset){
               return  loadedAsset.identifier === asset.identifier;
            }
          ).length === 0;
    });
  },

  preloadImage(src){
    console.log(`Preloading ${src}`);
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
      img.src = src
    })
  },

  preloadVideo(src){
    console.log(`Preloading ${src}`);
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.addEventListener('canplaythrough', () => resolve(true) );
      video.addEventListener('error', () => resolve(false) );
      video.src = src
    })
  }

}
