import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';
import moment from 'moment';
import builder from '@/services/slideBuilder'
Axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
const webpagesBaseUrl = process.env.VUE_APP_WEBPAGES_BASE_URL;

Vue.use(Vuex)

const clubSlide = {
  type: "club",
  duration: 10,
  priority: 0
};

export default new Vuex.Store({
  state: {
    office: {
      clubRousseau:{
        shopwindow: {
          theme: {
            name: 'light'
          }
        }
      },
      uri: ''
    },
    ratings: null,
    loading: true,
    busy: false,
    loadingMessage: "Chargement de la configuration...",
    configLoadingError : false,
    assetsLoaded: false,
    officeBuffer: null,
    officeBufferAssetsLoaded: false,
    views: [
          {
            type: "club",
            duration: 10,
            priority: 1
          }
        ],
    loadedAssets: []
  },
  getters:{
    getOffice(state) {
      return state.office;
    },
    getSettings(state) {
      return state.office.clubRousseau.shopwindow;
    },
     getLoadedAssets(state) {
      return state.loadedAssets;
    },
    getAssetsLoaded(state) {
      return state.assetsLoaded;
    },
    getViews(state) {
      return state.views;
    },
    getLoading(state) {
      return state.loading;
    },
    getConfigLoadingError(state) {
      return state.configLoadingError;
    },
    getLoadingMessage(state) {
      return state.loadingMessage;
    },
    getRatings(state) {
      return state.ratings;
    },
    getAssetByIdentifier: (state) => (identifier) => {
      return state.loadedAssets.find(asset => asset.identifier === identifier)
    }
  },
  mutations: {
    updateOffice(state, office) {
      state.office = office;
    },
    updateOfficeBuffer(state, officeBuffer) {
      state.officeBuffer = officeBuffer;
    },
    updateViews(state, views) {
      state.views = views;
    },
    updateLoadingMessage(state, message) {
      state.loadingMessage = message;
    },
    updateLoading(state, loading) {
      state.loading = loading;
    },
    updateConfigLoadingError(state, loadingError) {
      state.configLoadingError = loadingError;
    },
    updateBusy(state, busy) {
      state.busy = busy;
    },
    updateAssetsLoaded(state, loaded) {
      state.assetsLoaded = loaded;
    },
     updateRatings(state, ratings) {
      state.ratings = ratings;
    },
    addLoadedAsset(state, asset) {
      state.loadedAssets.push(asset);
    },
    swapConfig(state) {
      state.office = state.officeBuffer;
      state.officeBuffer = null;
    }
  },
  actions: {
    loadSettings(context, params) {
      return new Promise((resolve, reject) => {

          Axios.get(`/offices/${params.officeId}` , {
            headers: {
              Authorization : `Bearer ${process.env.VUE_APP_API_ACCESS_TOKEN}`,
                }
            })
            .then(response => {
              if ('data' in response && response.data ) {

                context.commit("updateOffice", {...response.data , uri : webpagesBaseUrl + response.data.uri});

                if(response.data.clubRousseau.shopwindow)
                {
                  let assets = builder.getAssetList( context.state.office );

                  Promise.all([
                    context.dispatch("loadAssets", { assetsList: assets }),
                    context.dispatch("loadRatings") ,
                  ] )
                    .then(function(){
                      let slides =  context.state.office.clubRousseau.shopwindow.slides ;
                      slides.push(clubSlide);
                      context.commit("updateViews", builder.buildViews( slides , context ));
                      context.commit("updateLoading", false);
                      resolve(context.state.office.clubRousseau.shopwindow)
                      console.log('Loading complete');
                  })
                }
                else{
                  context.commit("updateLoadingMessage" , "Pas de configuration enregistrée.");
                  console.log("Configuration is not defined.");
                  resolve(context.state.office.clubRousseau.shopwindow)
                }
              }
            })
            .catch(error => {
              console.error(error);
              context.commit("updateLoadingMessage" , `Impossible de charger la configuration pour ce bureau. <br />Consultez la console pour plus de détails. (${error.response.status}) `);
              context.commit("updateConfigLoadingError" , true );
            });
      });
    },

    pollForNewSettings(context, params) {
      console.log('Polling for new configuration');
      if(!context.state.busy){
        context.commit('updateBusy' , true);
        Axios.get(`/offices/${params.officeId}` , {
          headers: {
            Authorization : `Bearer ${process.env.VUE_APP_API_ACCESS_TOKEN}`,
          }
        })
          .then(response => {
            if ('data' in response && response.data ) {
              if(response.data.clubRousseau.shopwindow)
              {
                const hasChanged = response.data.clubRousseau.shopwindow.updatedAt !== context.state.office.clubRousseau.shopwindow.updatedAt;

                if(hasChanged) {
                  console.log(`Configuration has been updated on ${moment(response.data.clubRousseau.shopwindow.updatedAt)}`);
                  const officeBuffer = {...response.data, uri: webpagesBaseUrl + response.data.uri};
                  let assets = builder.getAssetList( officeBuffer , context.state.loadedAssets);
                  context.commit("updateOfficeBuffer", officeBuffer);

                  Promise.all([
                    context.dispatch("loadAssets", { assetsList: assets }),
                    context.dispatch("loadRatings") ,
                  ] )
                    .then(function(){
                      console.log('New configuration has been loaded, swapping');
                      context.commit("swapConfig");
                      let slides =  context.state.office.clubRousseau.shopwindow.slides ;
                      slides.push(clubSlide);
                      context.commit("updateViews", builder.buildViews( slides , context ));
                      context.commit('updateBusy' , false);
                    })

                }
                else{
                  console.log(`Already up-to-date. (${moment(context.state.office.clubRousseau.shopwindow.updatedAt)}) `);
                  console.log(`Fetching ratings only.`);
                  context.dispatch("loadRatings") ;
                  context.commit('updateBusy' , false);
                }
              }
              else{
                console.log('Configuration is not defined.')
              }
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
      else{
        console.log('Some requests are still pending.')
      }
    },
    loadAssets(context , params ){

      const {assetsList} = params;
      console.log(`Loading ${assetsList.length} new asset(s)...`);
      let requests = [];

      assetsList.forEach( function(asset) {
        if (asset.type === 'image')
          requests.push( builder.preloadImage(asset.url));
        else
          requests.push( builder.preloadVideo(asset.url));
      })

      context.commit("updateLoadingMessage", `Chargement des fichiers media...`);

      return new Promise( (resolve , reject ) => {
        Promise.all(requests).then(values => {
          values.forEach((value , index) => {
            if(!value)
              console.log(`[failure] ${assetsList[index].url}`);
            else
              console.log(`[success] ${assetsList[index].url}`);

            context.commit('addLoadedAsset' , { ...assetsList[index] , loaded: value})
          })
          context.commit("updateAssetsLoaded", true);
        }).catch(error => {
          console.log(error);
        }).finally(()=>{
          resolve(context.state.loadedAssets);
        });
      });
    },
    loadRatings(context , params){
      console.log('Fetching ratings');
      return new Promise((resolve) => {
          Axios.get(`/offices/${context.state.office.id}/ratings/1` ,{
              headers: {
                  Authorization : `Bearer ${process.env.VUE_APP_API_ACCESS_TOKEN}`,
              },
              withCredentials: false
          })
          .then(response => {
              context.commit("updateRatings", response.data);
          })
          .catch(error => {
              console.error(error);
          })
          .finally( () => {
            console.log('Fetching ratings complete');
            resolve(context.state.ratings)
          });
      });
    }
  },
  modules: {
  }
})

