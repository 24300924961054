<template>
  <footer class="absolute bottom-0 left-0 right-0 h-40 flex justify-center items-center content-center w-full px-16">
    <div  class="flex-grow text-center  w-1/3 ">
      <svg class="inline-block ml-auto svg-primary" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="256.375" height="45.792" viewBox="0 0 256.375 45.792">
        <g id="Groupe_2" data-name="Groupe 2" class="cls-2" transform="translate(0 0)">
          <path id="Tracé_23" data-name="Tracé 23" class="cls-3" d="M7.2,40.224a5.038,5.038,0,0,1-4.176-2.256Q0,33.84,0,24.1,0,10.272,7.056,3.6A12.825,12.825,0,0,1,15.648,0h.912q6.192,0,9.84,2.9A9.051,9.051,0,0,1,30.048,10.3a9.306,9.306,0,0,1-3.888,7.56Q22.272,20.93,15.552,21.7q-.242.1-.432,1.584-.1.816-.192,1.632t-.24,2.184q-.144,1.368-.408,3.576a26.462,26.462,0,0,1-.912,4.392,8.2,8.2,0,0,1-2.208,3.671A5.508,5.508,0,0,1,7.2,40.224m.576-3.455q1.728,0,2.352-2.928.334-1.584.5-3.1t.408-4.225q.239-2.711.48-4.584c-.161-.063-.536-.207-1.128-.432a11.234,11.234,0,0,1-1.2-.5c-.208-.111-.488-.288-.84-.527a1.865,1.865,0,0,1-.72-.84,4.094,4.094,0,0,1-.192-1.441c0-.639.144-.96.432-.96a6.187,6.187,0,0,1,2.112.745,6.593,6.593,0,0,0,1.968.744q.288-1.008.768-3.217a24.384,24.384,0,0,1,1.152-3.887,2.747,2.747,0,0,1,2.64-1.92q1.775,0,1.872,1.008-.194.672-1.488,3.695a11.966,11.966,0,0,0-1.248,4.465,14,14,0,0,0,7.488-2.712,6.779,6.779,0,0,0,2.784-5.472,5.937,5.937,0,0,0-2.4-5.04,10.727,10.727,0,0,0-6.576-1.8A10.419,10.419,0,0,0,10.128,6,13.076,13.076,0,0,0,6.48,11.04,34.127,34.127,0,0,0,3.984,24.1q0,12.67,3.792,12.672" transform="translate(0 0)"/>
          <path id="Tracé_24" data-name="Tracé 24" class="cls-3" d="M30.432,34.465A5.029,5.029,0,0,1,26.5,32.737a6.266,6.266,0,0,1-1.536-4.321,10.53,10.53,0,0,1,2.568-6.983,7.681,7.681,0,0,1,6.024-3.048,4.481,4.481,0,0,1,3.384,1.44A5.084,5.084,0,0,1,38.3,23.473a9.705,9.705,0,0,1-.192,1.536q3.12-.578,6.192-4.033a.685.685,0,0,1,.5-.191q.312,0,.648.456a1.532,1.532,0,0,1,.336.912.9.9,0,0,1-.192.648,15.165,15.165,0,0,1-7.968,5.279q-1.584,6.386-7.2,6.385m.72-3.072A3,3,0,0,0,33.48,30,5.478,5.478,0,0,0,34.7,27.217q-1.776-.768-1.776-1.969a3.408,3.408,0,0,1,1.248-2.4,1.5,1.5,0,0,0-.312-.913.962.962,0,0,0-.744-.288A3.7,3.7,0,0,0,30,23.785a8.44,8.44,0,0,0-1.3,4.44q0,3.167,2.448,3.168" transform="translate(0 0)"/>
          <path id="Tracé_25" data-name="Tracé 25" class="cls-3" d="M55.343,19.968a45.494,45.494,0,0,0-2.16,9.552l-.048.576c0,.737.224,1.1.672,1.1q1.536-.1,3.984-2.784a1.867,1.867,0,0,1,1.032-.48,1.6,1.6,0,0,1,.984.552,1.522,1.522,0,0,1,.576,1.032q0,1.105-2.952,3a9.678,9.678,0,0,1-4.728,1.9,2.55,2.55,0,0,1-2.88-2.832l.144-1.488a7.793,7.793,0,0,1-2.016,2.928A4.931,4.931,0,0,1,44.4,34.465a3.6,3.6,0,0,1-3.1-1.393,5.884,5.884,0,0,1-1.032-3.6,19.924,19.924,0,0,1,.984-6,23.974,23.974,0,0,1,1.56-4.1,1.715,1.715,0,0,1,1.512-.984,2.339,2.339,0,0,1,1.416.36c.319.24.48.464.48.672a29.252,29.252,0,0,1-1.152,3.528,22.288,22.288,0,0,0-1.344,5.664v.336q0,2.352,1.632,2.352a3.768,3.768,0,0,0,2.664-1.512,10.456,10.456,0,0,0,2.136-4.152l1.3-4.8a5.1,5.1,0,0,1,.936-2.016,1.671,1.671,0,0,1,1.2-.432,1.946,1.946,0,0,1,1.272.432,1.237,1.237,0,0,1,.528.96Z" transform="translate(0 0)"/>
          <path id="Tracé_26" data-name="Tracé 26" class="cls-3" d="M61.006,28.176a24.9,24.9,0,0,1,3.24-6.24q2.376-3.5,3.864-3.5,2.112,0,2.112,1.632a7.135,7.135,0,0,1-.5,2.424,7.922,7.922,0,0,0-.5,1.656q0,.72,1.032.72a8.148,8.148,0,0,0,3.384-1.392,1,1,0,0,1,.384-.048q.288,0,.744.528a1.53,1.53,0,0,1,.456.936.817.817,0,0,1-.192.6,8.553,8.553,0,0,1-5.616,2.592q-3.264,0-3.264-2.784,0-.1.768-3.12a9.241,9.241,0,0,0-.768,1.152,56.733,56.733,0,0,0-4.416,9.648,2.138,2.138,0,0,1-2.064,1.248q-1.968,0-1.968-3.168v-.383a34.956,34.956,0,0,1,1.488-10.129q.72-2.16,2.3-2.16a1.365,1.365,0,0,1,1.008.384,1.3,1.3,0,0,1,.384.96Z" transform="translate(0 0)"/>
          <path id="Tracé_27" data-name="Tracé 27" class="cls-3" d="M80.205,43.681q.144-1.585,1.248-7.176t1.272-6.145q.168-.551.84-2.736a29.191,29.191,0,0,1,1.68-4.248,11.217,11.217,0,0,1,1.728-2.616,7.606,7.606,0,0,1,2.4-1.68,8.269,8.269,0,0,1,3.528-.7A3.755,3.755,0,0,1,96.045,19.8a5.728,5.728,0,0,1,1.1,3.624c0,.353-.033.848-.1,1.488q2.4-.526,6.336-4.224a1.278,1.278,0,0,1,.576-.144.717.717,0,0,1,.552.384,1.748,1.748,0,0,1,.264,1.008,1.19,1.19,0,0,1-.528,1.008,16.6,16.6,0,0,1-3.6,3.024,14.2,14.2,0,0,1-4.416,2.064,11.686,11.686,0,0,1-3.072,4.609,6.565,6.565,0,0,1-4.512,1.824,4.125,4.125,0,0,1-2.832-1.248q-.144.576-1.032,5.327t-1.128,5.424a2.532,2.532,0,0,1-1.152,1.224,3.238,3.238,0,0,1-1.632.551l-.336-.478a1.743,1.743,0,0,1-.384-1.008ZM88.8,31.248a3.616,3.616,0,0,0,2.376-.96,7.279,7.279,0,0,0,1.848-2.5,2.05,2.05,0,0,1-1.2-2.016,2.846,2.846,0,0,1,.264-1.32,4.4,4.4,0,0,1,1.176-1.224.507.507,0,0,1,.192-.24q-.576-1.488-1.68-1.488a3.168,3.168,0,0,0-1.944.624,4.805,4.805,0,0,0-1.416,1.824,17.768,17.768,0,0,0-.888,2.16q-.312.96-.672,2.4T86.4,30.288a3.7,3.7,0,0,0,2.4.96" transform="translate(0 0)"/>
          <path id="Tracé_28" data-name="Tracé 28" class="cls-3" d="M105.261,34.368a4.18,4.18,0,0,1-3.7-1.944,9.459,9.459,0,0,1-1.3-5.3q0-1.008.048-1.536a51.287,51.287,0,0,1,3.648-14.5,19.766,19.766,0,0,1,3.7-5.976,6.379,6.379,0,0,1,4.656-2.376,2.985,2.985,0,0,1,2.3.936A3.3,3.3,0,0,1,115.485,6v.384a22.78,22.78,0,0,1-3.936,10.128,35.073,35.073,0,0,1-7.632,8.544,20.481,20.481,0,0,0-.192,2.352q0,3.888,2.3,3.888,2.4,0,5.328-3.36a1.138,1.138,0,0,1,.84-.336,1.425,1.425,0,0,1,.96.408,1.3,1.3,0,0,1,.456,1.008,1.834,1.834,0,0,1-.576,1.224q-3.648,4.13-7.776,4.128m5.856-27.7q-2.016,0-4.056,5.136a45.537,45.537,0,0,0-2.76,9.744,45.161,45.161,0,0,0,5.208-7.2q2.376-4.032,2.424-6.72,0-.96-.816-.96" transform="translate(0 0)"/>
          <path id="Tracé_29" data-name="Tracé 29" class="cls-3" d="M125.709,19.968a45.431,45.431,0,0,0-2.16,9.552l-.048.576c0,.737.224,1.1.672,1.1q1.536-.1,3.983-2.784a1.87,1.87,0,0,1,1.032-.48,1.6,1.6,0,0,1,.985.552,1.52,1.52,0,0,1,.575,1.032q0,1.105-2.951,3a9.68,9.68,0,0,1-4.729,1.9,2.55,2.55,0,0,1-2.88-2.832l.144-1.488a7.782,7.782,0,0,1-2.016,2.928,4.927,4.927,0,0,1-3.551,1.441,3.6,3.6,0,0,1-3.1-1.393,5.877,5.877,0,0,1-1.033-3.6,19.956,19.956,0,0,1,.984-6,23.949,23.949,0,0,1,1.561-4.1,1.713,1.713,0,0,1,1.511-.984,2.339,2.339,0,0,1,1.416.36c.319.24.481.464.481.672a29.468,29.468,0,0,1-1.152,3.528,22.3,22.3,0,0,0-1.345,5.664v.336q0,2.352,1.633,2.352a3.765,3.765,0,0,0,2.663-1.512,10.456,10.456,0,0,0,2.136-4.152l1.3-4.8a5.081,5.081,0,0,1,.936-2.016,1.671,1.671,0,0,1,1.2-.432,1.949,1.949,0,0,1,1.273.432,1.24,1.24,0,0,1,.528.96Z" transform="translate(0 0)"/>
          <path id="Tracé_30" data-name="Tracé 30" class="cls-3" d="M134.876,34.465a11,11,0,0,1-5.208-1.176,6.779,6.779,0,0,1-3.1-2.953q2.112-3.646,5.616-10.847A2.4,2.4,0,0,1,134.4,18a1.76,1.76,0,0,1,1.224.457,1.513,1.513,0,0,1,.5,1.176,2.2,2.2,0,0,1-.288,1.152,1.852,1.852,0,0,0-.288.96,4.032,4.032,0,0,0,1.056,1.776,33.916,33.916,0,0,1,2.088,2.879,5.3,5.3,0,0,1,1.031,2.761,3.832,3.832,0,0,1-.527,2.04q2.207-.72,4.7-3.84a.946.946,0,0,1,.7-.288q.408,0,.913.648a1.95,1.95,0,0,1,.5,1.1,1.852,1.852,0,0,1-.192.84,9.786,9.786,0,0,1-4.176,3.48,15.707,15.707,0,0,1-6.767,1.32m-1.2-2.736h.192q2.4,0,2.4-2.16T133.964,24L130.8,30.433a5.075,5.075,0,0,0,2.88,1.3" transform="translate(0 0)"/>
          <path id="Tracé_31" data-name="Tracé 31" class="cls-3" d="M151.338,28.752a11.483,11.483,0,0,1,2.5-7.176q2.5-3.24,5.712-3.24a5.3,5.3,0,0,1,1.681.288q2.206-15.936,8.928-15.936a3.339,3.339,0,0,1,2.424.96,3.433,3.433,0,0,1,1.08,2.256,7.982,7.982,0,0,1,.047,1.008,18.013,18.013,0,0,1-2.448,8.688q-1.342,2.5-2.135,3.792t-2.76,4.128q-1.97,2.832-2.256,3.264v.432q0,4.128,2.208,4.128,2.494,0,5.183-3.5a1.055,1.055,0,0,1,.769-.24,1.493,1.493,0,0,1,1.008.432,1.321,1.321,0,0,1,.479,1.008,1.853,1.853,0,0,1-.383,1.1q-3.312,4.225-7.44,4.224a4.924,4.924,0,0,1-4.7-3.312,5.039,5.039,0,0,1-5.04,3.408,4.415,4.415,0,0,1-3.456-1.584,6.018,6.018,0,0,1-1.393-4.128m5.617,2.352a2.528,2.528,0,0,0,2.184-1.272,12.279,12.279,0,0,0,1.319-4.632l.577-3.648a5.181,5.181,0,0,0-4.224,2.112,7.367,7.367,0,0,0-1.681,4.7V28.9q.146,2.208,1.825,2.208m12.48-24.432q-1.585,0-2.544,2.592a32.98,32.98,0,0,0-1.08,3.192,93.4,93.4,0,0,0-1.608,9.672,6.283,6.283,0,0,0,.383-.672q.1-.1,1.153-1.752t1.872-3.1a31.23,31.23,0,0,0,1.775-3.888,11.922,11.922,0,0,0,.961-4.248q0-1.8-.912-1.8" transform="translate(0 0)"/>
          <path id="Tracé_32" data-name="Tracé 32" class="cls-3" d="M180.353,14.784q-1.968,0-1.968-1.776a12.814,12.814,0,0,1,.768-3.936q.288-.768.792-2.04t.648-1.7q.48-1.536,1.728-1.536a.83.83,0,0,1,.672.384,1.277,1.277,0,0,1,.288.768q0,.386-.864,7.776a2.33,2.33,0,0,1-.672,1.512,1.939,1.939,0,0,1-1.392.552" transform="translate(0 0)"/>
          <path id="Tracé_33" data-name="Tracé 33" class="cls-3" d="M183.992,29.616a19.521,19.521,0,0,1,.768-4.968A20.127,20.127,0,0,1,186.3,20.4a4.031,4.031,0,0,1,1.32-1.656,2.871,2.871,0,0,1,1.248-.264,2.126,2.126,0,0,1,1.2.336.918.918,0,0,1,.5.72,3.439,3.439,0,0,1-.552,1.344,21.416,21.416,0,0,0-1.416,3.744,15.976,15.976,0,0,0-.865,4.464q0,2.258,1.68,2.257,2.016,0,4.848-3.265a1.163,1.163,0,0,1,.936-.48,1.485,1.485,0,0,1,1.008.408,1.252,1.252,0,0,1,.456.96,2.059,2.059,0,0,1-.528,1.224q-3.552,4.178-7.392,4.176a4.623,4.623,0,0,1-3.456-1.319,4.692,4.692,0,0,1-1.3-3.433m7.056-14.3q-2.592,0-2.592-2.256a2.333,2.333,0,0,1,.84-1.9,3.243,3.243,0,0,1,2.136-.7q2.4,0,2.4,2.16a2.572,2.572,0,0,1-.767,1.968,2.832,2.832,0,0,1-2.017.72" transform="translate(0 0)"/>
          <path id="Tracé_34" data-name="Tracé 34" class="cls-3" d="M206.792,31.009a10.279,10.279,0,0,0,4.416-2.833.751.751,0,0,1,.552-.192q.36,0,.768.649a2.14,2.14,0,0,1,.408,1.008,1.284,1.284,0,0,1-.1.552A10.18,10.18,0,0,1,209,33.217a9.681,9.681,0,0,1-3.84,1.056q-2.784,0-2.784-3.025a16.234,16.234,0,0,1,.96-4.56q.96-2.974.96-3.192t-.1-.216h-.048q-.817.337-3.432,3.816a27.072,27.072,0,0,0-3.192,4.921,6.192,6.192,0,0,1-.984,1.847,1.81,1.81,0,0,1-1.32.409q-1.776-.049-1.776-3.649a31.842,31.842,0,0,1,2.112-10.511,2.2,2.2,0,0,1,2.112-1.729,1.3,1.3,0,0,1,.96.384,1.129,1.129,0,0,1,.384.792,2.116,2.116,0,0,1-.048.553,44.147,44.147,0,0,0-1.776,7.1,29.417,29.417,0,0,1,4.9-6.6q2.4-2.278,4.272-2.28a2.545,2.545,0,0,1,1.68.6,1.683,1.683,0,0,1,.72,1.272,21.248,21.248,0,0,1-1.224,4.776,45.136,45.136,0,0,0-1.32,4.392,8.324,8.324,0,0,0-.1,1.177q0,.5.312.479c.208-.015.328-.023.36-.023" transform="translate(0 0)"/>
          <path id="Tracé_35" data-name="Tracé 35" class="cls-3" d="M220.232,35.857q0,1.631-.025,2.232t-.143,1.992a8.475,8.475,0,0,1-.937,3.024q-1.2,2.4-4.847,2.687h-.241q-3.646,0-3.647-8.641a79.725,79.725,0,0,1,1.727-15.7,60.775,60.775,0,0,1,2.281-8.592,20.8,20.8,0,0,1,3.528-6.48,6.178,6.178,0,0,1,4.752-2.592,2.959,2.959,0,0,1,2.4,1.032,4.559,4.559,0,0,1,.865,3,12.992,12.992,0,0,1-.865,4.368q-1.68,4.8-7.919,12.144a5.288,5.288,0,0,0,1.679.24,13.913,13.913,0,0,0,1.969-.144,5.1,5.1,0,0,0,.792-.192c.3-.1.527-.168.672-.216a7.281,7.281,0,0,0,.72-.312,6.8,6.8,0,0,0,.648-.336c.1-.063.343-.207.744-.432s.663-.376.792-.456a.663.663,0,0,1,.336-.12q.576,0,.575,1.152a2.7,2.7,0,0,1-.719,1.92q-1.44,1.584-7.1,1.776a21.328,21.328,0,0,1,1.367,4.32,20.846,20.846,0,0,1,.6,4.321m-5.424,6.863q2.4,0,2.4-5.471a27.258,27.258,0,0,0-.815-6.433,18.93,18.93,0,0,0-1.92-5.184q0,.241-.265,4.849t-.263,6.575q0,5.663.864,5.664m7.248-35.28a2.779,2.779,0,0,0-2.088,1.416,14.18,14.18,0,0,0-1.824,3.7q-.792,2.28-1.368,4.272t-1.032,4.032q-.458,2.041-.5,2.136,1.2-1.391,2.616-3.24a40.849,40.849,0,0,0,3.216-5.16,12.052,12.052,0,0,0,1.8-5.232q0-1.918-.815-1.92" transform="translate(0 0)"/>
          <path id="Tracé_36" data-name="Tracé 36" class="cls-3" d="M227.815,34.465a5.027,5.027,0,0,1-3.935-1.728,6.261,6.261,0,0,1-1.536-4.321,10.525,10.525,0,0,1,2.568-6.983,7.679,7.679,0,0,1,6.023-3.048,4.481,4.481,0,0,1,3.385,1.44,5.088,5.088,0,0,1,1.367,3.648,9.8,9.8,0,0,1-.191,1.536q3.118-.578,6.191-4.033a.685.685,0,0,1,.5-.191q.312,0,.649.456a1.539,1.539,0,0,1,.336.912.9.9,0,0,1-.193.648,15.162,15.162,0,0,1-7.968,5.279q-1.582,6.386-7.2,6.385m.721-3.072A3.005,3.005,0,0,0,230.864,30a5.478,5.478,0,0,0,1.224-2.783q-1.777-.768-1.777-1.969a3.408,3.408,0,0,1,1.248-2.4,1.5,1.5,0,0,0-.311-.913.965.965,0,0,0-.744-.288,3.7,3.7,0,0,0-3.12,2.137,8.43,8.43,0,0,0-1.3,4.44q0,3.167,2.448,3.168" transform="translate(0 0)"/>
          <path id="Tracé_37" data-name="Tracé 37" class="cls-3" d="M245.239,34.465a11,11,0,0,1-5.208-1.176,6.779,6.779,0,0,1-3.1-2.953q2.112-3.646,5.616-10.847A2.4,2.4,0,0,1,244.759,18a1.76,1.76,0,0,1,1.224.457,1.513,1.513,0,0,1,.5,1.176,2.2,2.2,0,0,1-.288,1.152,1.852,1.852,0,0,0-.288.96,4.032,4.032,0,0,0,1.056,1.776,33.913,33.913,0,0,1,2.088,2.879,5.3,5.3,0,0,1,1.032,2.761,3.833,3.833,0,0,1-.528,2.04q2.206-.72,4.7-3.84a.943.943,0,0,1,.7-.288c.271,0,.576.216.912.648a1.945,1.945,0,0,1,.5,1.1,1.865,1.865,0,0,1-.192.84,9.792,9.792,0,0,1-4.176,3.48,15.715,15.715,0,0,1-6.768,1.32m-1.2-2.736h.192q2.4,0,2.4-2.16T244.327,24l-3.168,6.433a5.075,5.075,0,0,0,2.88,1.3" transform="translate(0 0)"/>
        </g>
      </svg>
    </div>
    <div  class="flex-grow text-center w-1/3">
      <div class="inline-block p-1 bg-white ">
        <QRCanvas :options="options"  :width="180" :height="180" @updated="onUpdated"></QRCanvas>
      </div>
    </div>
    <div  class="flex-grow text-center w-1/3">
      <svg  class="m-auto " id="Logo_ClubRousseau" data-name="Logo ClubRousseau" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="218.206" height="218.207" viewBox="0 0 218.206 218.207" >
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_1" data-name="Rectangle 1" width="218.206" height="218.207" fill="none"/>
          </clipPath>
        </defs>
        <g id="Groupe_1" data-name="Groupe 1" clip-path="url(#clip-path)">
          <path id="Tracé_1" data-name="Tracé 1" d="M6.938,96.87s-12.235,12.235,0,24.468L96.87,211.27s12.234,12.234,24.468,0l89.931-89.932s12.235-12.235,0-24.468L121.338,6.938S109.1-5.3,96.87,6.938Z" fill="#fff"/>
          <path id="Tracé_2" data-name="Tracé 2" d="M6.938,96.87s-12.235,12.235,0,24.468L96.87,211.27s12.234,12.234,24.468,0l89.931-89.932s12.235-12.235,0-24.468L121.338,6.938S109.1-5.3,96.87,6.938Z" fill="none" stroke="#1d1d1b" stroke-width="3"/>
          <path id="Tracé_3" data-name="Tracé 3" d="M113.647,46.546a2.686,2.686,0,0,1,1.855,1.779,4.937,4.937,0,0,1-.229,2.871,16.47,16.47,0,0,1-1.829,3.607,38.859,38.859,0,0,1-3,3.989q-1.728,2.034-3.709,4.014t-3.965,3.659a3.4,3.4,0,0,0,.661,2.312,2.734,2.734,0,0,0,1.7.889,5.58,5.58,0,0,0,2.312-.2,12.087,12.087,0,0,0,2.49-1.016q1.066-.61.915.178a4.781,4.781,0,0,1-1.195,1.981,12.322,12.322,0,0,1-2.794,2.287,7.114,7.114,0,0,1-3.837,1.093,5.341,5.341,0,0,1-5.133-3.811,8.5,8.5,0,0,1-.1-4.371,27.152,27.152,0,0,1,1.727-5.437,37.724,37.724,0,0,1,2.973-5.564,32.172,32.172,0,0,1,3.685-4.751A15.006,15.006,0,0,1,110.065,47a4.457,4.457,0,0,1,3.582-.458m-9.2,13.923a23.1,23.1,0,0,0-1.117,2.947q1.879-1.88,3.811-4.192t3.43-4.4a26.168,26.168,0,0,0,2.236-3.583q.735-1.5.279-1.753c-.237-.134-.652-.025-1.245.331a9.719,9.719,0,0,0-2.083,1.88,27.881,27.881,0,0,0-2.566,3.506,40.6,40.6,0,0,0-2.745,5.259" fill="#1d1d1b"/>
          <path id="Tracé_4" data-name="Tracé 4" d="M123.281,61.633a3.578,3.578,0,0,1-.084,2.516,6.85,6.85,0,0,1-1.741,2.579,10.128,10.128,0,0,1-3.1,1.992,9.813,9.813,0,0,1-4.089.754,2.3,2.3,0,0,0,1.195,1.3,4.585,4.585,0,0,0,2.117.462,9.185,9.185,0,0,0,2.663-.441,10.061,10.061,0,0,0,2.831-1.4q.712-.462.838-.252t-.251.8a8.781,8.781,0,0,1-1.174,1.384,9.456,9.456,0,0,1-1.95,1.467,11.068,11.068,0,0,1-2.558,1.07,7.373,7.373,0,0,1-3,.188,6.707,6.707,0,0,1-2.789-.943,4.491,4.491,0,0,1-1.7-1.887,5.566,5.566,0,0,1-.482-2.642,8.991,8.991,0,0,1,.818-3.208,9.51,9.51,0,0,1,1.825-2.747,10.97,10.97,0,0,1,2.369-1.887,8.558,8.558,0,0,1,2.579-1.006,8.361,8.361,0,0,1,2.453-.168,4.585,4.585,0,0,1,1.991.629,2.977,2.977,0,0,1,1.238,1.447m-3.187,1.258A1.135,1.135,0,0,0,119,62.723a5.158,5.158,0,0,0-1.614.713,8.1,8.1,0,0,0-1.656,1.426,6.927,6.927,0,0,0-1.258,1.971,7.148,7.148,0,0,0,2.515-.525,8.554,8.554,0,0,0,1.993-1.111,4.006,4.006,0,0,0,1.153-1.279.814.814,0,0,0-.042-1.027" fill="#1d1d1b"/>
          <path id="Tracé_5" data-name="Tracé 5" d="M89.3,72.291H51.72a1.45,1.45,0,1,1,0-2.9H89.3a1.45,1.45,0,1,1,0,2.9" fill="#1d1d1b"/>
          <path id="Tracé_6" data-name="Tracé 6" d="M166.487,72.291h-36a1.45,1.45,0,0,1,0-2.9h36a1.45,1.45,0,0,1,0,2.9" fill="#1d1d1b"/>
          <path id="Tracé_7" data-name="Tracé 7" d="M166.782,124.936H52.023a1.449,1.449,0,0,1,0-2.9H166.782a1.449,1.449,0,0,1,0,2.9" fill="#1d1d1b"/>
          <path id="Tracé_8" data-name="Tracé 8" d="M53.043,132.77h4.475c3.71,0,6.423,1.3,6.423,4.917a4.32,4.32,0,0,1-2.574,4.035l4.291,6.053-3.989,1.275-4.638-7.514h-.046v6.958l-3.942.37Zm3.942,7.816c1.693.115,3.014-.441,3.014-2.319,0-1.9-1.391-2.2-3.014-2.133Z" fill="#1d1d1b"/>
          <path id="Tracé_9" data-name="Tracé 9" d="M82.586,140.725A8.489,8.489,0,1,1,74.1,132.4a8.414,8.414,0,0,1,8.488,8.326m-13.033,0a4.548,4.548,0,1,0,4.545-4.707,4.741,4.741,0,0,0-4.545,4.707" fill="#1d1d1b"/>
          <path id="Tracé_10" data-name="Tracé 10" d="M97.449,143.16c0,3.71-2.9,5.891-6.888,5.891-3.71,0-6.307-1.74-6.307-5.612V132.771L88.2,132.4v9.926c0,1.692.487,3.107,2.458,3.107,2.552,0,2.853-1.786,2.853-3.965v-8.7l3.942-.371Z" fill="#1d1d1b"/>
          <path id="Tracé_11" data-name="Tracé 11" d="M108.277,136.968a3.857,3.857,0,0,0-2.828-1.205c-.7,0-1.74.417-1.74,1.252,0,2.527,7.119.881,7.119,6.725,0,3.571-2.783,5.31-6.1,5.31a8.481,8.481,0,0,1-5.52-1.878l2.366-3.015a3.844,3.844,0,0,0,2.991,1.276c.812,0,2.087-.394,2.087-1.415,0-2.482-7.119-1-7.119-6.7,0-3.153,2.922-4.916,5.8-4.916a8.183,8.183,0,0,1,5.1,1.832Z" fill="#1d1d1b"/>
          <path id="Tracé_12" data-name="Tracé 12" d="M121.17,136.968a3.857,3.857,0,0,0-2.828-1.205c-.7,0-1.74.417-1.74,1.252,0,2.527,7.119.881,7.119,6.725,0,3.571-2.783,5.31-6.1,5.31a8.481,8.481,0,0,1-5.52-1.878l2.366-3.015a3.844,3.844,0,0,0,2.991,1.276c.812,0,2.087-.394,2.087-1.415,0-2.482-7.119-1-7.119-6.7,0-3.153,2.922-4.916,5.8-4.916a8.183,8.183,0,0,1,5.1,1.832Z" fill="#1d1d1b"/>
          <path id="Tracé_13" data-name="Tracé 13" d="M125.6,148.679V132.771h9l.7,3.363h-5.751v2.759h4.105v3.362h-4.105v3.062h6.238l-.974,3.362Z" fill="#1d1d1b"/>
          <path id="Tracé_14" data-name="Tracé 14" d="M143.685,132.4l8.581,15.838-4.013.812-1.623-3.409h-6.052l-1.6,3.409-3.827-.812,8.488-15.838Zm-2.156,10.3h4.22l-2.11-3.9Z" fill="#1d1d1b"/>
          <path id="Tracé_15" data-name="Tracé 15" d="M165.762,143.16c0,3.71-2.9,5.891-6.888,5.891-3.71,0-6.307-1.74-6.307-5.612V132.771l3.942-.371v9.926c0,1.692.487,3.107,2.458,3.107,2.552,0,2.853-1.786,2.853-3.965v-8.7l3.942-.371Z" fill="#1d1d1b"/>
          <path id="Tracé_16" data-name="Tracé 16" d="M90.1,156.916a9.057,9.057,0,1,1-8.881,9.055,8.976,8.976,0,0,1,8.881-9.055m0,13.9a4.852,4.852,0,1,0-5.022-4.849A5.058,5.058,0,0,0,90.1,170.82" fill="#e5332a"/>
          <path id="Tracé_17" data-name="Tracé 17" d="M110.73,156.916a9.057,9.057,0,1,1-8.881,9.055,8.976,8.976,0,0,1,8.881-9.055m0,13.9a4.852,4.852,0,1,0-5.022-4.849,5.058,5.058,0,0,0,5.022,4.849" fill="#f29100"/>
          <path id="Tracé_18" data-name="Tracé 18" d="M131.364,156.916a9.057,9.057,0,1,1-8.881,9.055,8.976,8.976,0,0,1,8.881-9.055m0,13.9a4.852,4.852,0,1,0-5.022-4.849,5.058,5.058,0,0,0,5.022,4.849" fill="#93c01f"/>
          <path id="Tracé_19" data-name="Tracé 19" d="M70.632,78.011c7.53,0,15.541,4.059,17.25,13.3H80.138c-1.655-4.219-5.02-6.089-9.506-6.089-6.622,0-11.055,4.807-11.055,11.91,0,6.409,4.326,11.75,11,11.75,4.647,0,8.439-2.19,9.72-6.73h7.69c-1.6,9.881-9.559,13.993-17.356,13.993-9.826,0-19.119-6.623-19.119-19.013,0-12.443,9.293-19.279,19.119-19.119" fill="#1d1d1b"/>
          <path id="Tracé_20" data-name="Tracé 20" d="M100.536,76.676V106c0,3.952,1.549,4.753,5.127,4.166l.267,5.607c-7.957,1.442-12.764-.534-12.764-9.773V76.676Z" fill="#1d1d1b"/>
          <path id="Tracé_21" data-name="Tracé 21" d="M134.075,90.027v18c0,2.4.106,3.258,2.4,3.1l.053,4.647c-3.632.854-9.4,1.228-9.4-3.739-1.442,2.991-5.02,4.113-8.225,4.113-7.423,0-9.56-4.967-9.56-11.376V90.027h7.531v14.74c-.16,3.632,1.816,5.5,4.432,5.5,2.992.053,5.287-2.19,5.287-5.661V90.027Z" fill="#1d1d1b"/>
          <path id="Tracé_22" data-name="Tracé 22" d="M141.549,76.783h7.424V90.989a15.777,15.777,0,0,1,5.982-1.228c7.156-.054,12.337,5.394,12.337,13.511,0,10.041-7,13.4-14.847,13.352a34.2,34.2,0,0,1-10.9-2.03Zm7.264,33.165a15.436,15.436,0,0,0,4.486.641c3.791-.054,6.515-2.083,6.515-7.317,0-4.646-2.67-7.316-6.142-7.476a9.3,9.3,0,0,0-4.859,1.335Z" fill="#1d1d1b"/>
        </g>
      </svg>
    </div>
  </footer>
</template>

<script>
  import {mapGetters} from 'vuex';

  const { QRCanvas } = require('qrcanvas-vue');

  export default{
    props:['officeUrl'],
    components: {
     QRCanvas
    },
    computed: {
      ...mapGetters({ office : "getOffice"}),
      options() {
        return {
          resize: false,
          data: `${this.office.uri}?utm_source=shopwindow`,
        };
      },
    },
    methods: {
      onUpdated(canvas) {
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 180, 180, 30);

      },
    },
  }
</script>
