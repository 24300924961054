import Vue from 'vue'
import VueRouter from 'vue-router'
import ShopWindow from '../views/ShopWindow'
import NotFound from '../views/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/office/:officeId?',
    name: 'ShopWindow',
    component: ShopWindow
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
