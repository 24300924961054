<template>
  <div v-if="settings" class="pb-40 h-full">
    <window-header></window-header>
    <div class="flex justify-center items-center flex-col px-8 h-4/5 ">
      <div class="selected-picture h-72 w-full mt-5  bg-cover bg-center" v-if="images.length">
        <vueper-slides
          fixed-height="540px"
          fade
          autoplay
          ref="slider"
          :arrows="false"
          :bullets="false"
          :duration="1000 * settings.duration / images.length - 500"
        >
          <vueper-slide v-for="(image , index) in images" :key="`slide_${index}`" >
            <template #content>
            <div class="selected-picture h-full w-full  bg-cover bg-center"  :style="{backgroundImage: `url(${image})`}" >
            </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="description p-8  m-auto" v-if="settings.config && settings.config.description ">
        <div>
          <h2 class="text-2xl font-bold text-center mb-2">Le mot de l'équipe</h2>
          <div  class="italic text-center" >
            <svg  style="margin-bottom:-40px; position: relative; bottom : 30px" class="h-10 svg-primary inline" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg>
            {{settings.config.description}}
            </div>
          </div>
      </div>
      <div class="p-8  reviews m-auto" v-if="settings.config && settings.config.showReviews && ratings.moyenne !== null">
        <div>
          <h2 class="text-2xl font-bold text-center mb-2">Les avis des élèves</h2>
          <div class="flex items-center">
            <star-rating :rating="parseFloat(ratings.moyenne)" :increment="0.5" :show-rating="false" :inline="true"/>
            <span class="text-4xl pl-3">{{parseFloat(ratings.moyenne).toFixed(1)}}/5</span>
            <span class="italic pl-3">({{ratings.nbtotal}} avis)</span>
          </div>
        </div>
      </div>
    </div>
    <window-footer></window-footer>
  </div>
</template>

<script>
  import windowFooter from '@/components/Footer'
  import windowHeader from '@/components/Header'
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  import StarRating from 'vue-star-rating';
  import { mapGetters } from 'vuex';
  export default{
    props:['settings'],
    components:{
      windowFooter,
      windowHeader,
      StarRating,
      VueperSlides,
      VueperSlide
    },
      computed:{
        ...mapGetters({ratings : "getRatings"}),
        images: function(){
          if(this.settings.config.selectedPictures && this.settings.config.selectedPictures.length > 0)
            return this.settings.config.selectedPictures;
          else
            return this.settings.config.selectedPicture ? [this.settings.config.selectedPicture] : []
        }
      }
  }
</script>
